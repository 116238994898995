import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store";
import axios from "@/utilities/axios";
import userQuestionAnswerService from "../../../services/userQuestionAnswer.service.js";
import { useStartupStore } from "@/stores/startup.store.js";
const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;

export function useForm(showModal, setLoading) {
    const form = ref({
        email: "",
        password: "",
    });

    const auth = useAuthStore();
    const router = useRouter();
    const startupStore = useStartupStore();

    async function login() {
        if (form.value.email !== "" && form.value.password !== "") {
            setLoading(true); // Emite o evento de loading
            try {
                const response = await axios.post(`${userBaseUrl}/login`, form.value);

                if (response.status === 201 || response.status === 200) {
                    const data = response.data;

                    if (data.success) {
                        const roles = data.roles;
                        const type = data.type;
                        const token = data.token;
                        localStorage.setItem("token", `${type} ${token}`);
                        auth.setAuthentication(true);
                        auth.includeRoles(roles);
                        auth.setLastLogin();
                        auth.setUserId(data.id);
                        const userQuestionAnswer = await userQuestionAnswerService.findByUserId(data.id);
                        const isFirstLogin = userQuestionAnswer && userQuestionAnswer.length === 0;

                        setTimeout(() => {
                            startupStore.registrarSePrimeiroLoginDoUsuario(isFirstLogin);

                            if (isFirstLogin) {
                                router.push("/loading?to=/boas-vindas");
                            }
                            else {
                                router.push("/loading?to=/acao?m=1");
                            }
                        }, 500);
                    }
                    else {
                        showModal("Usuário e/ou senha inválido, tente novamente.");
                    }
                }
                else {
                    showModal("Erro inesperado, por favor tente novamente mais tarde.");
                }
            }
            catch (err) {
                handleLoginError(err);
            }
            finally {
                setLoading(false); // Desativa o evento de loading
            }
        }
        else {
            showModal("Digite as informações necessárias para fazer o login.");
        }
    }

    function handleLoginError(err) {
        if (err.response && err.response.data) {
            if (err.response.data.error === "not_authorized" || err.response.data.error === "not_find_user") {
                showModal("Usuário e/ou senha inválido, tente novamente.");
            }
            else if (err.response.data.error === "approved_false") {
                showModal("Usuário necessita aprovação, entre em contato com o suporte.");
            }
            else {
                showModal("Erro na solicitação, se persistir contate o administrador.");
            }
        }
        else {
            showModal("Erro inesperado, por favor tente novamente mais tarde.");
        }
    }

    return { form, login };
}
